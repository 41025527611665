import React from 'react';
import { Spin, Typography } from '@duxton-web/ui/lib';
import Lottie from 'lottie-react';
import I18n from 'i18n-js';

import BLLoadingLottie from 'public/lotties/bl-loading.json';

import LegacySpace from './layout/LegacySpace';

import styles from './Loading.less';

export const FullPageLoadingSpinner = () => (
  <div className={styles.container}>
    <Spin />
  </div>
);

export const BLFullPageLoading = () => (
  <div className={styles.blOverlayContainer}>
    <LegacySpace vertical align="center" size={6}>
      <Lottie
        animationData={BLLoadingLottie}
        style={{ width: '100px', height: '100px' }}
      />

      <LegacySpace vertical size={4} align="center">
        <Typography.Title className={styles.title} level={4}>
          {I18n.t('mex_business_listing_heading_submitting_your_details')}
        </Typography.Title>

        <Typography.Paragraph className={styles.description}>
          {I18n.t('mex_business_listing_body_only_take_a_moment')}
        </Typography.Paragraph>
      </LegacySpace>
    </LegacySpace>
  </div>
);

export default {};
