import scribe from '@grab-x/scribe-web-sdk';

import { isProd } from '@common/utils/EnvHelper';

let initialized = false;
function initialize() {
  if (!initialized) {
    scribe.initialize(
      'mex-mobile-web',
      window.Grab.config.env === 'production',
    );
    initialized = true;
  }
}

export function sendEvent(stateName, eventName, parameters = {}) {
  initialize();
  scribe.sendEvent(eventName, { ...parameters, STATENAME: stateName });
}

export function forceUpdate() {
  initialize();
  return scribe.forceUpdate().finally(() => Promise.resolve());
}

/**
 * @returns {{ sendEvent: ({ eventName: string, stateName: string, parameters: any }, forceUpdate?: boolean, withToken?: boolean) => void }}
 */
export function BusinessListingInstrumentation() {
  let instrumentationInitialized = false;

  function initInstrumentation({ withToken } = {}) {
    if (instrumentationInitialized) return;
    instrumentationInitialized = true;

    scribe.initialize('pax.track.food_business', isProd());
    if (withToken) scribe.setAuthToken(sessionStorage.getItem('token'), 'jwt');
  }

  // eslint-disable-next-line no-shadow
  function sendEvent(
    { stateName, eventName, parameters = {} } = {
      stateName: '',
      eventName: '',
    },
    // eslint-disable-next-line no-shadow
    forceUpdate = false,
    withToken = true,
  ) {
    initInstrumentation({ withToken });
    scribe.sendEvent(eventName, { ...parameters, STATENAME: stateName });

    if (forceUpdate) {
      return scribe.forceUpdate();
    }

    return null;
  }

  return { sendEvent };
}
