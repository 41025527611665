// recoverable backend error codes
export const ERROR_INVALID_OTP = 'LA4002';
export const ERROR_INVALID_USERNAME = 'LA4007';

// unrecoverable error codes
export const ERROR_ALREADY_MAPPED = 'LA4009';

// network related errors
export const ERROR_CONNECTION_TIMEOUT = 'ECONNABORTED';
export const ERROR_NETWORK_ERROR = 'Network Error';

// deep links
export const SEE_USERNAME_DEEPLINK =
  'https://grab.onelink.me/k45t/fckraylx?af_dp=grabmex%3A%2F%2Fsetting%3FscreenType%3Ddynamic%26route%3Daccount%2Fpersonal_profile_v2';
export const GENERATE_OTP_DEEPLINK =
  'https://grab.onelink.me/k45t/fckraylx?af_dp=grabmex%3A%2F%2Fstorefront';

// instrumentation related constants
export const States = {
  LINE_VERIFICATION: 'LINE_VERIFICATION',
  LINE_UNLINK_CONFIRMATION: 'LINE_UNLINK_CONFIRMATION',
  LINE_UNLINK_SUCCESS: 'LINE_UNLINK_SUCCESS',
  LINE_UNLINK_FAILED: 'LINE_UNLINK_FAILED',

  LINE_MEX_LOGIN: 'LINE_MEX_LOGIN',
  LINE_LINK_SUCCESS: 'LINE_LINK_SUCCESS',
  LINE_LINK_FAILED: 'LINE_LINK_FAILED',

  LINE_TNC_DETAILS: 'LINE_TNC_DETAILS',
};

export const Events = {
  DEFAULT: 'DEFAULT',
  CLICK_RETRY: 'CLICK_RETRY',
  CLICK_CONTINUE: 'CLICK_CONTINUE',
  CLICK_OK: 'CLICK_OK',
  CLICK_TNC: 'CLICK_TNC',
  CLICK_AGREE: 'CLICK_AGREE',
  CLICK_ALLOW: 'CLICK_ALLOW',
};

export const SOURCE = 'LINE_APP';
