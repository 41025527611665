import React from 'react';
import PropTypes from 'prop-types';
import styles from './Spinner.less';

const Spinner = ({ show }) => (
  <div className={show ? styles.spinnerOverlay : styles.spinnerOverlayHidden}>
    <div className={styles.spinner} />
  </div>
);

Spinner.propTypes = {
  show: PropTypes.bool.isRequired,
};

export default Spinner;
