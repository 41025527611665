import React, { useEffect } from 'react';
import { useOutletContext } from 'react-router-dom';
import I18n from 'i18n-js';
import styles from './Terms.less';
import { sendEvent } from '../commons/tracker';
import { Events, SOURCE, States } from '../mex-auth/constants';

const Terms = () => {
  const { termsHandlers: { onAcceptButtonClicked } } = useOutletContext();

  useEffect(() => {
    sendEvent(States.LINE_TNC_DETAILS, Events.DEFAULT);
  }, []);

  const handleAcceptClick = e => {
    sendEvent(States.LINE_TNC_DETAILS, Events.CLICK_AGREE, {
      SOURCE,
    });

    onAcceptButtonClicked(e);
  };

  return (
    <div className={styles.layout}>
      <div className={styles.smallSpacer} />
      <div style={{ height: 'calc(100% - 128px)' }}>
        <iframe
          title="Line T&C"
          src={window.Grab.config.lineTncUrl}
          width="100%"
          height="100%"
          style={{ border: 0 }}
        />
      </div>
      <div className={styles.footer}>
        <button onClick={handleAcceptClick} className={styles.acceptButton}>
          {I18n.t('mex_line_th_button_agree')}
        </button>
      </div>
    </div>
  );
};

export default Terms;
