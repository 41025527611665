import React from 'react';
import clsx from 'clsx';
import styles from './LegacySpace.less';
const ALIGN_MAP = {
    start: 'items-start',
    center: 'items-center',
    baseline: 'items-baseline',
    stretch: 'items-stretch',
    end: 'items-end',
};
const JUSTIFY_MAP = {
    start: 'justify-start',
    center: 'justify-center',
    spaceBetween: 'space-between',
    end: 'justify-end',
};
const LegacySpace = ({ vertical, align = 'stretch', justify = 'start', size = 0, className = '', children, style = {}, }) => {
    const directionClassName = clsx({
        [styles['flex-col']]: vertical,
        [styles['flex-row']]: !vertical,
    });
    const alignClassName = styles[ALIGN_MAP[align]];
    const justifyClassName = styles[JUSTIFY_MAP[justify]];
    const spaceDirection = vertical ? 'space-y' : 'space-x';
    const spaceClassName = styles[`${spaceDirection}-${size}`];
    const classNames = [
        alignClassName,
        justifyClassName,
        directionClassName,
        spaceClassName,
        className,
    ]
        .join(' ')
        .trim();
    return (React.createElement("div", { className: classNames, style: style }, children));
};
export default LegacySpace;
