/* eslint-disable react/no-array-index-key */
// we disable this warning because the elements are static

import React, { useState } from 'react';
import I18n from 'i18n-js';
import { useOutletContext } from 'react-router-dom';
import {
  Events,
  GENERATE_OTP_DEEPLINK,
  SEE_USERNAME_DEEPLINK,
  States,
} from '../mex-auth/constants';
import styles from './MexAuthForm.less';
import InfoIcon from '../../public/images/info.svg';
import Spinner from './Spinner';
import NumberInput from '../commons/NumberInput';
import { sendEvent } from '../commons/tracker';

const numberOnlyPattern = /[0-9]{6}/;

const MexAuthForm = () => {
  const {
    formState: {
      username,
      dynamicCode,
      tncAccepted,
      privacyPolicyAccepted,
      isSubmitting,
    },
    formHandlers: {
      setUsername,
      setDynamicCode,
      setPrivacyPolicyAccepted,
      setTncAccepted,
      onOpenTncClicked,
      onSubmit,
    },
  } = useOutletContext();

  const [dynamicCodeDirty, setDynamicCodeDirty] = useState(false);
  const [usernameDirty, setUsernameDirty] = useState(false);

  const handleTncClick = e => {
    sendEvent(States.LINE_MEX_LOGIN, Events.CLICK_TNC);
    onOpenTncClicked(e);
  };

  const HeaderText = () => {
    const headerParagraphs = I18n.t(
      'mex_line_th_body_link_grabmerchant_to_line',
    ).split('\n');

    return (
      <div>
        {headerParagraphs.map((text, idx) => (
          <p style={{ marginBottom: 0 }} key={`text_${idx}`}>
            {text}
          </p>
        ))}
      </div>
    );
  };

  const onDynamicCodeChanged = e => {
    setDynamicCodeDirty(true);
    if (/^[0-9]*$/.test(e.target.value)) {
      setDynamicCode(e.target.value);
    }
  };

  const onUsernameChanged = e => {
    setUsernameDirty(true);
    setUsername(e.target.value);
  };

  const onTncClicked = e => {
    setTncAccepted(e.target.checked);
  };

  const onPrivacyPolicyClicked = e => {
    setPrivacyPolicyAccepted(e.target.checked);
  };

  const isDynamicCodeValid = numberOnlyPattern.test(dynamicCode);
  const isUsernameValid = username.trim() !== '';
  const isFormValid =
    isDynamicCodeValid &&
    isUsernameValid &&
    tncAccepted &&
    privacyPolicyAccepted;

  const howtoSteps = I18n.t('mex_line_th_list_how_generate_otp').split('\n');
  const terms = /(.+)<a\shref=.link.>(.+)<\/a>/g.exec(
    I18n.t('mex_line_th_heading_consent_tc'),
  );

  const privacyPolicy = /(.+)<a\shref=.link.>(.+)<\/a>(.*)/g.exec(
    I18n.t('mex_line_th_body_agree_privacy'),
  );

  return (
    <div className={styles.layout}>
      <div className={styles.smallSpacer} />
      <HeaderText />
      <div className={styles.bigSpacer} />
      <form className={styles.form}>
        <div className={styles.formItem}>
          <div className={styles.labelContainer}>
            <label className={styles.label} htmlFor="usernameInput">
              {I18n.t('mex_line_th_label_grabmerchant_email')}
            </label>
            <a
              className={styles.deeplink}
              href={SEE_USERNAME_DEEPLINK}
              role="button"
              target="_blank"
              rel="noopener noreferrer"
              aria-hidden
            >
              {I18n.t('mex_line_th_label_grabmerchant_see_username_deeplink')}
            </a>
          </div>
          <input
            id="usernameInput"
            data-testid="usernameInput"
            type="text"
            className={styles.textInput}
            onChange={onUsernameChanged}
            value={username}
            placeholder={I18n.t(
              'mex_line_th_textfield_placeholder_enter_email',
            )}
          />
          {usernameDirty &&
            !isUsernameValid && (
              <span className={styles.fieldError}>
                {I18n.t('mex_line_th_label_error_grabmerchant_email')}
              </span>
            )}
          <p className={styles.usernameHint}>
            {I18n.t('mex_line_th_helpertext_see_grabmerchant_email')}
          </p>
        </div>

        <div className={styles.formItem}>
          <div className={styles.labelContainer}>
            <label className={styles.label} htmlFor="dynamicCodeInput">
              {I18n.t('mex_line_th_label_grabmerchant_otp')}
            </label>
            <a
              className={styles.deeplink}
              href={GENERATE_OTP_DEEPLINK}
              role="button"
              target="_blank"
              rel="noopener noreferrer"
              aria-hidden
            >
              {I18n.t('mex_line_th_label_grabmerchant_generate_otp_deeplink')}
            </a>
          </div>
          <NumberInput
            id="dynamicCodeInput"
            data-testid="dynamicCodeInput"
            maxLength={6}
            className={styles.textInput}
            onChange={onDynamicCodeChanged}
            value={dynamicCode}
            placeholder={I18n.t(
              'mex_line_th_textfield_placeholder_enter_6digit_otp',
            )}
          />
          {dynamicCodeDirty &&
            !isDynamicCodeValid && (
              <span className={styles.fieldError}>
                {I18n.t('mex_line_th_label_error_grabmerchant_otp')}
              </span>
            )}
          <div className={styles.dynamicCodeHint}>
            <img src={InfoIcon} width={24} height={24} alt="info icon" />
            <div>
              <h4 style={{ marginTop: 0 }}>
                {I18n.t('mex_line_th_heading_how_generate_otp')}
              </h4>
              <ol style={{ paddingInline: 'initial' }}>
                {howtoSteps.map((step, idx) => (
                  <li key={`step_${idx}`}>{step}</li>
                ))}
              </ol>
            </div>
          </div>
        </div>

        <div className={styles.tncContainer}>
          <div className={styles.tncRow}>
            <div className={styles.tncCheckbox}>
              <input
                data-testid="tncCheckbox"
                type="checkbox"
                style={{ height: 24, width: 24 }}
                onChange={onTncClicked}
                checked={tncAccepted}
              />
            </div>
            <div>
              <span>{terms[1]}</span>
              <a
                role="button"
                tabIndex={0}
                style={{ fontWeight: 600 }}
                onClick={handleTncClick}
                aria-hidden="true"
              >
                {terms[2]}
              </a>
            </div>
          </div>
          <div className={styles.tncRow}>
            <div className={styles.tncCheckbox}>
              <input
                data-testid="privacyPolicyCheckbox"
                type="checkbox"
                style={{ minHeight: 24, minWidth: 24 }}
                onChange={onPrivacyPolicyClicked}
                checked={privacyPolicyAccepted}
              />
            </div>
            <div>
              <span>{privacyPolicy[1]}</span>
              <a
                role="button"
                tabIndex={0}
                style={{ fontWeight: 600 }}
                href="https://www.grab.com/th/terms-policies/privacy-notice/"
                rel="noopener noreferrer"
                target="_blank"
                aria-hidden="true"
              >
                {privacyPolicy[2]}
              </a>
              <span>{privacyPolicy[3]}</span>
            </div>
          </div>
        </div>
        <div className={styles.continueButtonWrapper}>
          <button
            data-testid="continueButton"
            disabled={!isFormValid}
            className={
              isFormValid
                ? styles.continueButtonEnabled
                : styles.continueButtonDisabled
            }
            onClick={onSubmit}
          >
            {I18n.t('mex_line_th_button_continue')}
          </button>
        </div>
      </form>

      {<Spinner show={isSubmitting} />}
    </div>
  );
};

export default MexAuthForm;
