/* eslint-disable react/no-array-index-key */
// we disable this warning because the elements are static

import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { parse } from 'query-string';
import I18n from 'i18n-js';
import styles from './MexDeauthForm.less';
import InfoIcon from '../../public/images/info.svg';
import { api } from '../../common/utils/service';
import Spinner from './Spinner';
import BottomSheet from '../commons/BottomSheet';
import {
  ERROR_CONNECTION_TIMEOUT,
  ERROR_INVALID_OTP,
  ERROR_NETWORK_ERROR,
  Events,
  SOURCE,
  States,
} from './constants';
import NumberInput from '../commons/NumberInput';
import { forceUpdate, sendEvent } from '../commons/tracker';

const numberOnlyPattern = /[0-9]{6}/;

const MexDeauthForm = () => {
  const [dynamicCode, setDynamicCode] = useState('');
  const [isSubmitting, setSubmitting] = useState(false);
  const [dirty, setDirty] = useState(false);

  const [showError, setShowError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [errorHeading, setErrorHeading] = useState('');

  const location = useLocation();
  const { line_user_id: lineUserId } = parse(location.search);

  useEffect(() => {
    sendEvent(States.LINE_UNLINK_CONFIRMATION, Events.DEFAULT, {
      LINE_USER_ID: lineUserId,
    });
  }, []);

  const HeaderText = () => (
    <div>
      <h2>{I18n.t('mex_line_th_heading_confirm_unlink_line_acct')}</h2>
      <p style={{ marginBottom: 0 }}>
        {I18n.t('mex_line_th_body_confirm_unlink_line_acct_shorter')}
      </p>
    </div>
  );

  const onDynamicCodeChanged = e => {
    setDirty(true);
    setDynamicCode(e.target.value);
  };

  const removeLineAuth = async () => {
    const payload = {
      dynamic_code: dynamicCode,
      line_user_id: lineUserId,
    };
    return api('POST', '/mex-web/chat/v1/line-deauth', payload);
  };

  function trackUnlinkError(error, retry) {
    const buttonEventName = retry ? Events.CLICK_RETRY : Events.CLICK_CONTINUE;
    sendEvent(States.LINE_UNLINK_CONFIRMATION, buttonEventName, {
      ERROR_MSG: error,
      LINE_USER_ID: lineUserId,
    });
    sendEvent(States.LINE_UNLINK_FAILED, Events.DEFAULT, {
      SOURCE,
      LINE_USER_ID: lineUserId,
    });
  }

  function trackUnlinkSuccess(retry) {
    const buttonEventName = retry ? Events.CLICK_RETRY : Events.CLICK_CONTINUE;
    sendEvent(States.LINE_UNLINK_CONFIRMATION, buttonEventName, {
      LINE_USER_ID: lineUserId,
    });
    sendEvent(States.LINE_UNLINK_SUCCESS, Events.DEFAULT, {
      SOURCE,
      LINE_USER_ID: lineUserId,
    });
  }

  const onSubmit = async (e, retry = false) => {
    e.preventDefault();
    setSubmitting(true);

    const { error, status, errCode } = await removeLineAuth();
    setSubmitting(false);

    if (error == null && status === 204) {
      trackUnlinkSuccess(retry);
      await forceUpdate();
      window.open(`${window.Grab.config.amityUrl}/unlink-success`, '_self');
    } else if (errCode === ERROR_INVALID_OTP) {
      setShowError(true);
      setErrorHeading(I18n.t('mex_line_th_heading_error_invalid_otp'));
      setErrorMessage(I18n.t('mex_line_th_body_error_invalid_otp'));
      trackUnlinkError(error, retry);
    } else if (
      error === ERROR_NETWORK_ERROR ||
      errCode === ERROR_CONNECTION_TIMEOUT
    ) {
      setShowError(true);
      setErrorHeading(I18n.t('mex_line_th_heading_submit_error'));
      setErrorMessage(
        I18n.t('mex_line_th_heading_internet_not_connected_error'),
      );
    } else {
      trackUnlinkError(error, retry);
      await forceUpdate();
      window.open(`${window.Grab.config.amityUrl}/unlink-fail`, '_self');
    }
  };

  const onCloseError = () => {
    setShowError(false);
    sendEvent(States.LINE_UNLINK_FAILED, Events.CLICK_OK, {
      SOURCE,
      LINE_USER_ID: lineUserId,
    });
  };

  const onTryAgain = e => {
    setShowError(false);

    // give time for the bottom sheet to close before submitting again for a better UX
    const timeout = setTimeout(() => {
      onSubmit(e, true);
      clearTimeout(timeout);
    }, 500);
  };

  const isDynamicCodeValid = numberOnlyPattern.test(dynamicCode);

  if (!lineUserId) {
    return <div>Missing parameter</div>;
  }

  const howtoSteps = I18n.t('mex_line_th_list_how_generate_otp').split('\n');

  return (
    <div className={styles.layout}>
      <div className={styles.smallSpacer} />
      <HeaderText />
      <div className={styles.bigSpacer} />
      <form className={styles.form}>
        <div className={styles.formItem}>
          <label className={styles.label} htmlFor="dynamicCodeInput">
            {I18n.t('mex_line_th_label_grabmerchant_otp')}
          </label>
          <NumberInput
            id="dynamicCodeInput"
            data-testid="dynamicCodeInput"
            maxLength={6}
            className={styles.textInput}
            onChange={onDynamicCodeChanged}
            value={dynamicCode}
            placeholder={I18n.t(
              'mex_line_th_textfield_placeholder_enter_6digit_otp',
            )}
          />
          {dirty &&
            !isDynamicCodeValid && (
              <span className={styles.fieldError}>
                {I18n.t('mex_line_th_label_error_grabmerchant_otp')}
              </span>
            )}
          <div className={styles.dynamicCodeHint}>
            <img src={InfoIcon} width={24} height={24} alt="info icon" />
            <div>
              <h4 style={{ marginTop: 0 }}>
                {I18n.t('mex_line_th_heading_how_generate_otp')}
              </h4>
              <ol style={{ paddingInline: 'initial' }}>
                {howtoSteps.map((step, idx) => (
                  <li key={`step_${idx}`}>{step}</li>
                ))}
              </ol>
            </div>
          </div>
        </div>

        <div className={styles.continueButtonWrapper}>
          <button
            data-testid="continueButton"
            disabled={!isDynamicCodeValid}
            className={
              isDynamicCodeValid
                ? styles.continueButtonEnabled
                : styles.continueButtonDisabled
            }
            onClick={onSubmit}
          >
            {I18n.t('mex_line_th_button_continue')}
          </button>
        </div>
      </form>
      <BottomSheet
        open={showError}
        description={<div>{errorMessage}</div>}
        onOK={onTryAgain}
        onCancel={onCloseError}
        image={require('public/images/error-image.png')}
        contentTitle={errorHeading}
        onClose={onCloseError}
        OKText={I18n.t('mex_button_try_again')}
        cancelText={I18n.t('mex_button_later')}
        dataTestId={'bottomSheetContainer'}
      />
      {isSubmitting && <Spinner />}
    </div>
  );
};

export default MexDeauthForm;
