export const TAB_HOME = 'HOME';
export const TAB_SAFETY = 'SAFETY_INCIDENTS';
export const TAB_QUALITY = 'ORDER_QUALITY';
export const TAB_CANCELLATIONS = 'ORDER_FULFILMENT';
export const TAB_CUSTOMER_RATING = 'CUSTOMER_RATINGS';
export const TAB_PREP_TIME = 'ON_TIME_PREPARATION';
export const DAYS_29 = 29 * 24 * 60 * 60 * 1000;

export const defaultLocale = 'en';

export const hideSliderLabel = {
  display: 'none',
};

// the default width is creating extra space that makes the entire page scroll horizontally. so decreasing the width. the width is automatically calculated depends on the number of marks
export const removeExtraSpacing = { width: '20%', marginLeft: '-10%' };
