import React from 'react';
import PropTypes from 'prop-types';
import Drawer from '@duxton-web/ui/lib/drawer';
import Button from '@duxton-web/ui/lib/button';
import './BottomSheet.less';

const BottomSheet = props => {
  const {
    OKText = 'OK',
    cancelText = 'Cancel',
    contentTitle,
    description,
    onOK,
    onCancel,
    image,
    withCancelButton = true,
    dataTestId,
    className,
  } = props;

  const handleCancel = () => {
    if (onCancel) onCancel();
  };

  const handleOK = e => {
    if (onOK) onOK(e);
  };

  const mergeClassname = `bottom-sheet__root ${className || ''}`;

  return (
    <Drawer
      {...props}
      placement="bottom"
      closable={false}
      className="bottom-sheet__drawer"
      rootClassName={mergeClassname}
      width="100%"
    >
      <div data-testid={dataTestId} className="bottom-sheet__container">
        {image && (
          <div className="img-header">
            <img alt={image} src={image} />
          </div>
        )}
        <div className="bottom-sheet__content--title">
          {contentTitle && contentTitle}
        </div>
        <div className="bottom-sheet__content--description">
          {description && description}
        </div>
        <Button type="primary" block onClick={handleOK}>
          {OKText}
        </Button>
        {withCancelButton && (
          <Button
            type="secondary"
            block
            onClick={handleCancel}
            className="bottom-sheet__button--cancel"
          >
            {cancelText}
          </Button>
        )}
      </div>
    </Drawer>
  );
};

BottomSheet.propTypes = {
  open: PropTypes.bool.isRequired,
  contentTitle: PropTypes.string.isRequired,
  description: PropTypes.oneOfType([
    PropTypes.string.isRequired,
    PropTypes.node.isRequired,
  ]),
  onClose: PropTypes.func,
  onOK: PropTypes.func,
  onCancel: PropTypes.func,
  OKText: PropTypes.string,
  cancelText: PropTypes.string,
  image: PropTypes.string,
  withCancelButton: PropTypes.bool,
  dataTestId: PropTypes.string,
  className: PropTypes.string,
};

BottomSheet.defaultProps = {
  open: false,
  contentTitle: '',
  description: '',
  onClose: () => {},
  onOK: () => {},
  onCancel: () => {},
  OKText: 'OK',
  cancelText: 'Cancel',
  image: '',
  withCancelButton: true,
  dataTestId: '',
  className: '',
};

export default BottomSheet;
