import React, { lazy, Suspense, useMemo } from 'react';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { StyleProvider } from '@ant-design/cssinjs';

import { FullPageLoadingSpinner } from '@components/commons/Loading';

import { isProd } from '@common/utils/EnvHelper';

import MexAuthForm from './mex-auth/MexAuthForm';
import Terms from './mex-auth/Terms';
import MexDeauthForm from './mex-auth/MexDeauthForm';
import LottiePlayer from './insights-reels/LottiePlayer';

const BLContainer = lazy(() => import('./business/BLContainer'));
const PhotoContainer = lazy(() =>
  import('./business/manage/photo/PhotoContainer'),
);

const ClaimPage = lazy(() => import('./business/claim/Claim'));
const PromptPage = lazy(() => import('./business/prompt'));
const PromptQuestionnairePage = lazy(() =>
  import('./business/prompt/verification-method-questionnaire'),
);
const ManagePage = lazy(() => import('./business/manage/Manage'));
const ImageGuideLinePage = lazy(() =>
  import('./business/manage/photo/ImageGuideLine'),
);
const ImagePreviewPage = lazy(() =>
  import('./business/manage/photo/ImagePreview'),
);
const ManageLinkInstagram = lazy(() =>
  import('./business/manage/LinkInstagram'),
);
const ManageUnlinkInstagram = lazy(() =>
  import('./business/manage/UnlinkInstagram'),
);
const BusinessTestPage = lazy(() => import('./business/test/TestPage'));

const MqsScoreInfo = lazy(() => import('./MqsScoreInfo'));
const Home = lazy(() => import('./Home'));
const OAuthRedirect = lazy(() => import('./OAuthRedirect'));
const ManageLinkInstagramOAuthRedirect = lazy(() =>
  import('./business/manage/LinkInstagramOAuthRedirect'),
);
const MexAuthContainer = lazy(() => import('./mex-auth/MexAuthContainer'));
const TokenExchange = lazy(() => import('./mex-auth/TokenExchange'));
const ConsentPage = lazy(() => import('./mex-register/pages/ConsentPage'));
const UserInfo = lazy(() => import('./mex-register/pages/UserInfo'));
const OTP = lazy(() => import('./mex-register/pages/OTP'));
const SetupCredential = lazy(() =>
  import('./mex-register/pages/SetupCredential'),
);
const SuccessPage = lazy(() => import('./mex-register/pages/SuccessPage'));

// GXS Landing Page
const GXSLandingPage = lazy(() => import('./gxs-landing-page/GXSLandingPage'));

const App = () => {
  const router = useMemo(
    () =>
      createBrowserRouter([
        { path: '/', Component: Home },
        {
          path: 'business',
          Component: BLContainer,
          children: [
            {
              path: 'claim',
              children: [
                { index: true, Component: PromptPage },
                { path: 'onboarding', Component: PromptQuestionnairePage },
                { path: 'form', Component: ClaimPage },
              ],
            },
            {
              path: 'manage',
              children: [
                { index: true, Component: ManagePage },
                {
                  path: 'photo',
                  Component: PhotoContainer,
                  children: [
                    {
                      index: true,
                      Component: ImageGuideLinePage,
                    },
                    {
                      path: 'preview',
                      Component: ImagePreviewPage,
                    },
                  ],
                },
                { path: 'link-instagram', Component: ManageLinkInstagram },
                { path: 'unlink-instagram', Component: ManageUnlinkInstagram },
              ],
            },
            {
              path: 'oauth/redirect',
              Component: ManageLinkInstagramOAuthRedirect,
            },
            !isProd() && { path: 'test', Component: BusinessTestPage },
          ],
        },
        { path: 'mqs-score-info', Component: MqsScoreInfo },
        { path: 'lottie-player', Component: LottiePlayer },
        {
          path: 'mex-auth',
          Component: MexAuthContainer,
          children: [
            { path: 'form', Component: MexAuthForm },
            { path: 'terms', Component: Terms },
            { path: 'deauth', Component: MexDeauthForm },
          ],
        },
        { path: 'mex-auth/token-exchange', Component: TokenExchange },
        {
          path: 'mex-register',
          children: [
            {
              path: '',
              Component: ConsentPage,
            },
            {
              path: 'user-info',
              Component: UserInfo,
            },
            {
              path: 'otp',
              Component: OTP,
            },
            {
              path: 'setup-credential',
              Component: SetupCredential,
            },
            {
              path: 'success',
              Component: SuccessPage,
            },
          ],
        },
        { path: 'oauth/redirect', Component: OAuthRedirect },
        { path: 'gxs-landing-page', Component: GXSLandingPage },
      ]),
    [],
  );

  return (
    <StyleProvider hashPriority="high">
      <Suspense fallback={<FullPageLoadingSpinner />}>
        <RouterProvider router={router} />
      </Suspense>
    </StyleProvider>
  );
};

export default App;
