import React from 'react';
import PropTypes from 'prop-types';

const ProgressBar = ({
  animCount,
  currentIndex,
  progress,
  safeAreaPadding,
}) => {
  const renderProgressBars = () =>
    Array.from({ length: animCount }, (_, index) => (
      <div
        key={index}
        data-testid="progress-bar-container"
        style={{
          flex: 1,
          height: '100%',
          margin: '0 4px',
          backgroundColor: 'rgba(255, 255, 255, 0.24)',
        }}
      >
        <div
          data-testid="progress-bar"
          style={{
            width: index === currentIndex ? `${progress}%` : '100%',
            height: '100%',
            backgroundColor: currentIndex >= index ? 'white' : 'transparent',
          }}
        />
      </div>
    ));

  return (
    <div
      data-testid="ProgressBar"
      style={{
        display: 'flex',
        position: 'absolute',
        top: 10 + safeAreaPadding,
        left: 20,
        right: 20,
        height: '2px',
      }}
    >
      {renderProgressBars()}
    </div>
  );
};

ProgressBar.propTypes = {
  animCount: PropTypes.number.isRequired,
  currentIndex: PropTypes.number.isRequired,
  progress: PropTypes.number.isRequired,
  safeAreaPadding: PropTypes.number.isRequired,
};

export default ProgressBar;
