import axios from 'axios';
import { get } from 'lodash';

axios.defaults.timeout = 60000;

export const api = (method, url, data = {}, config = {}) =>
  axios({
    url,
    method,
    [method.toLowerCase() === 'get' ? 'params' : 'data']: data,
    baseURL: window.Grab.config.apiGatewayBaseUrl,
    ...config,
  })
    .then(res => ({
      status: res.status,
      data: res.data,
      error: null,
    }))
    .catch(err => {
      window.console.log(
        `[Error] uri: ${url} message:${err.message} ${
          err.response
            ? `response_data: ${JSON.stringify(err.response.data)}`
            : ''
        }`,
      );
      return {
        status: err.response ? err.response.status : 0,
        data: {},
        error: get(err, ['response', 'data', 'message']) || err.message || err,
        errData: err.response ? err.response.data : {},
        errCode: get(err, ['response', 'data', 'error', 'code'], err.code),
      };
    });

export default api;
