export const NATIVE_ACTIONS = {
  FINISHED: 'finished',
  INDEX_CHANGE: 'index_change',
  LAST_SEEN: 'last_seen',
};

// Define message types for communication
export const MessageType = {
  DATA_ANIM: 'data_anim',
  NEXT_REEL: 'next_reel',
  PREV_REEL: 'prev_reel',
  HOLD_REEL: 'hold_reel',
  PLAY_REEL: 'play_reel',
};

// Call the finished handler in Flutter
export const callFinishedHandler = () => {
  if (window.flutter_inappwebview) {
    window.flutter_inappwebview.callHandler(NATIVE_ACTIONS.FINISHED);
  }
};

export const callLastSeenHandler = (index, playingDuration) => {
  if (window.flutter_inappwebview) {
    window.flutter_inappwebview.callHandler(NATIVE_ACTIONS.LAST_SEEN, {
      index,
      playingDuration,
    });
  }
};
