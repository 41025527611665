import React from 'react';
import PropTypes from 'prop-types';

const NumberInput = props => {
  const handleOnChange = e => {
    if (props.onChange) {
      if (props.maxLength && e.target.value.length <= props.maxLength) {
        props.onChange(e);
      } else if (!props.maxLength) {
        props.onChange(e);
      }
    }
  };

  return (
    <input
      {...props}
      onChange={handleOnChange}
      type="tel"
      pattern="\d+"
      className={`[&::-webkit-inner-spin-button]:appearance-none ${
        props.className
      }`}
    />
  );
};

NumberInput.propTypes = {
  onChange: PropTypes.func,
  maxLength: PropTypes.number,
  className: PropTypes.string,
};

NumberInput.defaultProps = {
  onChange: null,
  className: '',
  maxLength: null,
};

export default NumberInput;
